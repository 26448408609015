<template>

    <div>

        <div class="mt-5">

            <el-row type="flex" align="middle">
                <el-col :span="8"></el-col>
                <el-col :span="8" class="text-center"><span class="view-title">{{campaign.name}}</span></el-col>
                <el-col :span="8"></el-col>
            </el-row>

        </div>

        <div class="mt-4 pt-4 pb-4 px-5 bg-white border border-gray rounded">

            <el-row class="mt-1" type="flex" align="middle">

                <el-col>
                    <div v-if="!editing" class="breadcrumbs" @click="goBack">
                        <svg-icon class="mr-1" icon-class="back-arrow"/>
                        Volver a listado de Campañas
                    </div>
                </el-col>

                <el-col class="d-flex flex-row justify-content-end">
                    <template v-if="editing">
                        <div>
                            <el-button type="text" :disabled="loading" @click="editing = false">
                                Cancelar
                            </el-button>
                        </div>

                        <div class="ml-2">
                            <el-button type="success" :loading="loading" @click="saveCampaign">
                                Guardar
                            </el-button>
                        </div>
                    </template>

                    <template v-else>
                        <div class="ml-2">
                            <el-button :disabled="!campaign.uuid" @click="editing = true">
                                Editar Campaña
                            </el-button>
                        </div>
                    </template>
                </el-col>

            </el-row>

            <el-row class="mt-4">
                <el-col>

                    <campaign-form
                        v-if="campaign.uuid && editing"
                        ref="campaignForm" v-model="campaign"/>

                    <campaign-detail v-else :campaign="campaign" @deleted="goBack"/>

                </el-col>
            </el-row>

        </div>

    </div>

</template>

<script>
    import {dialogs, messages} from '../../../constants/text-constants';
    import {mapGetters} from 'vuex';
    import {updateCampaign} from "../../../api/campaign-api";
    import CampaignForm from "../components/CampaignForm";
    import CampaignDetail from "./components/CampaignDetail";

    export default {
        name: "CakeProfile",
        components: {CampaignDetail, CampaignForm},
        data() {
            return {
                campaign: {},
                loading: false,
                editing: false
            };
        },
        created() {
            this.loadCampaign();
        },
        computed: {
            ...mapGetters([
                'campaignById',
            ]),
        },
        methods: {
            loadCampaign() {
                this.campaign = Object.assign({}, this.campaignById(this.$route.params.uuid));
            },
            goBack() {
                this.$router.push({name: 'campaigns-list'})
            },
            saveCampaign() {
                this.$refs.campaignForm.validate().then(isValid => {
                    if (isValid) {
                        this.loading = true;
                        updateCampaign(this.campaign.uuid, this.campaign)
                            .then((campaign) => {
                                this.$message.success(messages.campaign.update.success);
                                this.refreshCampaign(campaign)
                                this.editing = false;
                            })
                            .catch(error => {
                                if (error.response.status === 464) {
                                    this.$alert('Ya existe una campaña con el mismo nombre de código', {
                                        confirmButtonText: 'De acuerdo'
                                    }).catch(() => {});
                                } else {
                                    this.$message.error(messages.campaign.update.error);
                                }
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                });
            },
            refreshCampaign(campaign) {
                this.campaign = Object.assign({}, campaign);
            }
        },
    }
</script>
