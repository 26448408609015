<template>
    <div v-loading="loading">
        <el-row :gutter="20">
            <el-col :span="8">

                <el-row>
                    <el-col>
                        <label>
                            Nombre de Campaña
                        </label>
                        <div>{{campaign.name}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Descripción
                        </label>
                        <div>{{campaign.description}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Fecha de Inicio
                        </label>
                        <div>{{campaign.startDate | moment(dateFormat)}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Fecha de Finalización
                        </label>
                        <div>{{campaign.endDate | moment(dateFormat)}}</div>
                    </el-col>
                </el-row>

                <el-row v-if="campaign.useWithFavorite" class="mt-5">
                    <el-col>
                        <el-switch :disabled="true" v-model="campaign.useWithFavorite"/>
                        <span class="switch-label ml-2">Disponible como favorita</span>
                    </el-col>
                </el-row>

                <el-row class="mt-5">
                    <el-col class="cursor-pointer mt-5">
                        <div @click="confirmDeleteCampaign">
                            <svg-icon icon-class="delete"/>
                            Eliminar Campaña
                        </div>
                    </el-col>
                </el-row>

            </el-col>

            <el-col :span="8">

                <el-row>
                    <el-col>
                        <label>
                            Tipo de Campaña
                        </label>
                        <div>{{campaignType[this.campaign.type]}}</div>
                    </el-col>
                </el-row>

                <el-row v-if="campaign.code" class="mt-4">
                    <el-col>
                        <label>
                            Código
                        </label>
                        <div>{{campaign.code}}</div>
                    </el-col>
                </el-row>

                <el-row v-if="campaign.discount" class="mt-4">
                    <el-col>
                        <label>
                            Descuento
                        </label>
                        <div>{{campaign.discount}}{{campaignType[this.campaign.type] === campaignType.MONEY_DISCOUNT ? '€' : '%'}}</div>
                    </el-col>
                </el-row>


                <span v-if="campaign.usesLimit">

                     <el-row class="mt-5">
                        <el-col>
                            <el-switch :disabled="true" v-model="campaign.usesLimit"/>
                            <span class="switch-label ml-2">Limitar Usos</span>
                        </el-col>
                    </el-row>

                     <el-row class="mt-4">
                        <el-col>
                            <label>
                                Nº Usos totales
                            </label>
                            <div>{{campaign.totalUses}}</div>
                        </el-col>
                    </el-row>

                    <el-row class="mt-4">
                        <el-col>
                            <label>
                                Nº Usos por usuario
                            </label>
                            <div>{{campaign.usesPerPerson}}</div>
                        </el-col>
                    </el-row>

                </span>

            </el-col>

            <el-col :span="8">

                <el-row v-if="campaign.scopeLimit" class="mb-4">
                    <el-col>
                        <el-switch :disabled="true" v-model="campaign.scopeLimit"/>
                        <span class="switch-label ml-2">Limitar alcance</span>
                    </el-col>
                </el-row>

                <el-row v-if="Array.isArray(campaign.cities) && campaign.cities.length" class="mb-4">
                    <el-col>
                        <label>
                            Ciudades
                        </label>
                        <div
                            class="mt-1"
                            v-for="city in campaign.cities"
                            :key="city.name">{{city.name}}
                        </div>
                    </el-col>
                </el-row>

                <el-row v-if="Array.isArray(campaign.tags) && campaign.tags.length" class="mb-4">
                    <el-col>
                        <label>
                            Tags
                        </label>
                        <div
                            class="mt-1"
                            v-for="tag in campaign.tags"
                            :key="tag.name">{{tag.name}}
                        </div>
                    </el-col>
                </el-row>

                <el-row v-if="Array.isArray(campaign.cakeshops) && campaign.cakeshops.length" class="mb-4">
                    <el-col>
                        <label>
                            Pastelerías
                        </label>
                        <div
                            class="mt-1"
                            v-for="cakeshop in campaign.cakeshops"
                            :key="cakeshop.name">{{cakeshop.name}}
                        </div>
                    </el-col>
                </el-row>

                <el-row v-if="Array.isArray(campaign.cakes) && campaign.cakes.length" class="mb-4">
                    <el-col>
                        <label>
                            Tartas
                        </label>
                        <div
                            class="mt-1"
                            v-for="cake in campaign.cakes"
                            :key="cake.name">{{cake.name}}
                        </div>
                    </el-col>
                </el-row>

            </el-col>
        </el-row>
    </div>
</template>

<script>

    import {campaignType} from "../../../../constants/campaign-constants";
    import {dialogs, messages} from "../../../../constants/text-constants";
    import {deleteCampaign} from "../../../../api/campaign-api";

    export default {
        name: "CampaignDetail",
        props: {
            campaign: Object
        },
        data() {
            return {
                loading: false,
                dateFormat: 'DD/MM/YYYY'
            };
        },
        computed: {
            campaignType() {
                return campaignType;
            }
        },
        methods: {
            confirmDeleteCampaign() {
                this.$confirm(dialogs.campaign.delete.text, {
                    confirmButtonText: dialogs.campaign.delete.confirm,
                    cancelButtonText: dialogs.campaign.delete.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.deleteCampaign();
                }).catch(() => {});
            },
            deleteCampaign() {
                this.loading = true;
                deleteCampaign(this.campaign.uuid).then(() => {
                    this.$message.success(messages.campaign.delete.success);
                    this.$emit('deleted');
                }).catch(() => {
                    this.$message.error(messages.campaign.delete.error);
                })
                .finally(() => this.loading = false);
            },
        }
    }
</script>

<style scoped lang="scss">
    .switch-label {
        font-size: 12px;
        letter-spacing: 0.02em;
    }
    .cursor-pointer :hover {
        font-weight: bold;
        .svg-icon {
            width: 1.2em;
            height: 1.2em;
        }
    }
</style>
